<template>
  <div>
  <div class="page">
  <NavBar v-if="route.path!='/'"></NavBar>
  <router-view/>
  </div>
  <Footer></Footer>
</div>
</template>

<script setup>
import { MainPage } from "./store";
import { useRoute } from "vue-router";
const {defineAsyncComponent, onMounted}=require("@vue/runtime-core");
const NavBar = defineAsyncComponent(()=>import('@/layout/Nav.vue'))
const Footer = defineAsyncComponent(()=>import('@/layout/Footer.vue'))
const route = useRoute()
const store = MainPage()
onMounted(()=>{
  store.GetDetail()
  store.GetImages()
})
</script>

<style scoped>
.page{
  opacity: 1;
}
</style>
