import axios from 'axios'
import showToast from '@/config/Toast'
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
    
  });
  instance.defaults.headers.common['Content-Type'] = 'application/json'
  instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  instance.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE'
  instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    let  err = error.response.data.message
    if(err){
      showToast(err)
    }else{
      showToast('Internet Error / Server Not Respond')
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
  });
  export default  instance;