
import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "about" */ '../views/Career.vue')
  },
  {
    path: '/career_detail/:id',
    name: 'career_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/JobDetail.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import(/* webpackChunkName: "about" */ '../views/Booking.vue')
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/term_condition',
    name: 'term_condition',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermCondition.vue')
  },
  {
    path: '/service/:id',
    name: 'service',
    component: () => import('../views/Service.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router
