import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createPinia } from 'pinia'
import VueLazyLoad from 'vue3-lazyload'
const pinia = createPinia()

createApp(App)
.use(router)
.use(VueLazyLoad, {
    // options...
  })
.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDNS7nXnbp8nqbAzU5h1nuqAK5kaWAsP90',
        // language: 'de',
    }
})
.use(pinia)
.mount('#app')

