import { defineStore } from 'pinia'
import API from '@/config/API.js'
import axios from 'axios'
import showToast from '@/config/Toast'
export const MainPage = defineStore('MainPage', {
    state: () => {
        return {
         data:'',
         load:false,
         serviceDetail:'',
         singleService:'',
         services:[],
         reviews:[],
         content:'',
         detail:[],
         career:'',
         singleCareer:'',
         images:'',
        }
      },
  actions: {
    async GetContent(){
      const response = await API.get("contpage/GetBy/Rent ACar")
      if(response.status==200)
      {
        this.content=response.data
      }           
    },
    
    async GetDetail(){
      const response = await API.get("company-details/GetBy/Rent ACar")
      if(response.status==200)
      {
        this.detail=response.data[0]
      }           
    },

    async Getservices(){
      const response = await API.get("services/GetBy/Rent ACar")
      if(response.status==200)
      {
        this.services =response.data
       
        this.GetservicesD(this.services[0].serviceD[0])
      }           
 },

 async GetServiceById(id){
  const response = await API.get("services/GetById/"+id)
  if(response.status==200)
  {
    this.singleService = response.data
    this.GetservicesD(this.singleService.serviceD[0])
  }           
},

 async GetservicesD(id){
  const response = await API.get("service-detail/GetById/"+id)
  if(response.status==200)
  {
    this.serviceDetail =response.data.detail
  }           
},

async GetImages(){
  const response = await API.get("image-media/GetBy/Rent ACar")
  if(response.status==200)
  {
    this.images=response.data
  }           
},

async GetReview(){
  const response = await API.get("review/GetBy/Rent ACar")
  if(response.status==200)
  {
    this.reviews =response.data
  }           
},


    async ContactUs(payload){
      try{
        this.load = true
        const response = await API.post("/contact-Us",payload)
        if(response.status==200)
        {
          this.load = false
         showToast('query submit successfully','success')
         return true
        }   
      }catch(error){
        this.load = false
      }
                 
    },
    async BookingForm(payload){
     const response = await axios.post("https://alsharafgroup.paratech.com.pk/api/bookingform/Post",payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
  })
     },
     async inqureyEmail(data){
      const response = await API.post("InquiryEmail",data)          
    },          
  async GetCareer(){
    const response = await API.get("career/GetBy/Rent ACar")
    if(response.status==200)
    {
      this.career =response.data
    }           
  },
  async SCareer(payload){
    const response = await API.get("career/GetById/"+payload)
    if(response.status==200)
    {
      this.singleCareer = response.data
    }           
  },
  async Apply(payload){
    try{
      const formData = new FormData();
      Object.keys(payload).forEach(key => formData.append(key, payload[key]));
      this.load = true
     const response = await API.post("/career-detail",formData)
     if(response.status==200)
     {
      
       this.load = false
      showToast('Application submit successfully','success')
      return  true
     }        
}catch(error){
  this.load= false
}
  }
  }
  })